import React, { useEffect } from "react";
import PureCounter from "@srexi/purecounterjs";
const StatsCounter = () => {
    useEffect(()=>{new PureCounter()},[])
    return (
        <>
            <section id="stats-counter" className="stats-counter">
                <div className="container" data-aos="fade-up">

                    <div className="row gy-4 align-items-center">

                        <div className="col-lg-6">
                            <img src={require("../../assets/img/stats-img.png")} alt="" className="img-fluid" />
                        </div>

                        <div className="col-lg-6">

                            <div className="stats-item d-flex align-items-center">
                                <div className="d-flex me-2"><span data-purecounter-start="0" data-purecounter-end="90" data-purecounter-duration="1" className="purecounter"></span><span>%</span></div>
                                <p><strong>Our Team</strong> Meet the dedicated minds driving innovation at IFS INDIA.</p>
                            </div>

                            <div className="stats-item d-flex align-items-center">
                            <div className="d-flex me-2"><span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="1" className="purecounter"></span><span>%</span></div>
                                <p><strong>Our Services</strong> Empowering businesses with innovative digital solutions tailored needs.</p>
                            </div>

                            <div className="stats-item d-flex align-items-center">
                            <div className="d-flex me-2"><span data-purecounter-start="0" data-purecounter-end="95" data-purecounter-duration="1" className="purecounter"></span><span>%</span></div>
                                <p><strong>Our clients</strong> Our clients range from startups to Fortune companies.</p>
                            </div>

                            <div className="stats-item d-flex align-items-center">
                            <div className="d-flex me-2"><span data-purecounter-start="0" data-purecounter-end="98" data-purecounter-duration="1" className="purecounter"></span><span>%</span></div>
                                <p><strong>Our awards and recognition</strong> Proud recipients of numerous prestigious awards.</p>
                            </div>

                            <div className="stats-item d-flex align-items-center">
                            <div className="d-flex me-1"><span data-purecounter-start="0" data-purecounter-end="100" data-purecounter-duration="1" className="purecounter"></span><span>%</span></div>
                                <p><strong>Our commitment to quality</strong> Ensuring unwavering commitment to quality.</p>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
            <section id="call-to-action" className="call-to-action">
                <div className="container text-center" data-aos="zoom-out">
                    <a href="https://youtu.be/XYqvFF0EZdE" className="glightbox play-btn"></a>
                    <h3>Call To Action</h3>
                    <p> IFS India Mercantile Pvt Ltd specializes in software development, network infrastructure, cybersecurity, and cloud computing, catering to diverse client needs. With a dedicated team of experts, we deliver innovative, cost-effective IT solutions to help clients achieve their business goals.</p>
                    <a className="cta-btn" href="#contact">Call To Action</a>
                </div>
            </section>
        </>
    )
}

export default StatsCounter;