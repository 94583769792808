import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";

const Hero = () => {
    const homeRef = useRef(null);
    const location= useLocation();

    useEffect(()=>{
        if(location.hash==="#home"){
            homeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[location,homeRef.current])
    return (
        <>
            <section id="home" ref={homeRef} className="hero shadow-sm">
                <div className="container position-relative">
                    <div className="row gy-5" data-aos="fade-in">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
                            <h2>Powerful Digital Solutions</h2>
                            <p>Empower your business with expert IT services and strategic manpower consulting. We streamline your technology operations and connect you with the perfect talent to achieve success.</p>
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <a href="#about" className="btn-get-started">Get Started</a>
                                <a href="https://youtu.be/XYqvFF0EZdE" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <img src={require('../../assets/img/3623862.jpg')} className="img-fluid" alt="" data-aos="zoom-out" data-aos-delay="100" />
                        </div>
                    </div>
                </div>

                <div className="icon-boxes position-relative">
                    <div className="container position-relative">
                        <div className="row gy-4 mt-5">

                            <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="icon-box shadow">
                                    <div className="icon"><i className="bi bi-laptop"></i></div>
                                    <h4 className="title"><a href="#services" className="stretched-link">Web Development</a></h4>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="icon-box shadow">
                                    <div className="icon"><i className="bi bi-phone"></i></div>
                                    <h4 className="title"><a href="#services" className="stretched-link">App Development</a></h4>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon-box shadow">
                                    <div className="icon"><i className="bi bi-cloud"></i></div>
                                    <h4 className="title"><a href="#services" className="stretched-link">Cloud Computing</a></h4>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div className="icon-box shadow">
                                    <div className="icon"><i className="bi bi-person-workspace"></i></div>
                                    <h4 className="title"><a href="#services" className="stretched-link">IT Consulting</a></h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section >

        </>
    )
}

export default Hero;