import React from "react";
import Breadcrumbs from "./Breadcrumbs";
import BlogSection from "./BlogSection";

const BlogMain = () => {
    return(
        <>
            <main id="main">
                <Breadcrumbs />
                <BlogSection />
            </main>
        </>
    )
}

export default BlogMain;