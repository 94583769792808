import React, { useEffect, useReducer, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
const Blog = () => {
    const blogRef = useRef(null);
    const location = useLocation();

    useEffect(()=>{
        if(location.hash==="#recent-posts"){
            blogRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[location,blogRef.current])
    return (
        <>
            <section id="recent-posts" ref={blogRef} className="recent-posts sections-bg">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                        <h2>Recent Blog Posts</h2>
                    </div>

                    <div className="row gy-4">

                        <div className="col-xl-4 col-md-6">
                            <article>

                                <div className="post-img">
                                    <img src={require("../../assets/img/blog/pexels-lukas-574069.jpg")} alt="" className="img-fluid" />
                                </div>



                                <h2 className="title">
                                    <Link to="/blog/blog1">Rise of Low-Code Development</Link>
                                </h2>

                                <p className="post-category">With over a decade experience, we’ve established ourselves…</p>
                                <div className="d-flex align-items-center">
                                    <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                                    <div className="post-meta">
                                        <p className="post-author-list text-secondary">Admin</p>

                                    </div>
                                </div>

                            </article>
                        </div>

                        <div className="col-xl-4 col-md-6">
                            <article>

                                <div className="post-img">
                                    <img src={require("../../assets/img/blog/blog-2.jpg")} alt="" className="img-fluid" />
                                </div>



                                <h2 className="title">
                                    <Link to="/blog/blog2">Embracing Continuous Delivery</Link>
                                </h2>

                                <p className="post-category">With over a decade experience, we’ve established ourselves…</p>
                                <div className="d-flex align-items-center">
                                    <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                                    <div className="post-meta">
                                        <p className="post-author-list text-secondary">Admin</p>

                                    </div>
                                </div>

                            </article>
                        </div>

                        <div className="col-xl-4 col-md-6">
                            <article>

                                <div className="post-img">
                                    <img src={require("../../assets/img/blog/pexels-pixabay-60504.jpg")} alt="" className="img-fluid" />
                                </div>



                                <h2 className="title">
                                    <Link to="/blog/blog3">The Future of Cybersecurity</Link>
                                </h2>

                                <p className="post-category">With over a decade experience, we’ve established ourselves…</p>
                                <div className="d-flex align-items-center">
                                    <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                                    <div className="post-meta">
                                        <p className="post-author-list text-secondary">Admin</p>

                                    </div>
                                </div>

                            </article>
                        </div>

                    </div>

                </div>
                <div className="pt-4" data-aos="fade-up">
                    <Link to='/all-blog' className="btn btn-secondary rounded-5 px-4 py-2 shadow">Read More</Link>
                </div>
            </section>
        </>
    )
}

export default Blog;