import React,{useEffect, useState} from "react";
import AboutUs from "./AboutUs";
import Client from "./Client";
import StatsCounter from "./StatsCounter";
import OurServices from "./OurServices";
import Testimonial from "./Testimonial";
import Portfolio from "./Portfolio";
import OurTeam from "./OurTeam";
import PricingSection from "./PricingSection";
import Faq from "./Faq";
import Blog from "./Blog";
import Contacts from "./Contacts";
import Aos from "aos";
import 'aos/dist/aos.css'
import Technologies from "./Technologies";
import Career from "./Career";
const MainPage = () => {
    const[submitting,setSubmitting] = useState(false);
    useEffect(() => {
        Aos.init({ duration: 2000 }); // Adjust the duration as needed
    }, []);
    const closeJobModal = () =>{
        const modal = document.getElementById("close-job-modal");
        setSubmitting(true)
        setTimeout(() => {
            setSubmitting(false);
            modal.click();
        }, 1500);
    }
    return(
        <>
            <main id="main">
                <AboutUs />
                <Client />
                <StatsCounter />
                <OurServices />
                <Technologies />
                <Testimonial />
                {/* <Portfolio /> */}
                {/* <OurTeam /> */}
                <Career />
                {/* <PricingSection /> */}
                <Faq />
                <Blog />
                <Contacts />
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Job Application</h1>
        <button id="close-job-modal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div class="container">
  <div class="row mx-0 justify-content-center">
    <div class="px-lg-2 px-xl-0 px-xxl-3">
      <form
        method="POST"
        class="w-100 rounded-1 p-4 border bg-white"
        // action="https://herotofu.com/start"
        onSubmit={(e)=>{e.preventDefault();closeJobModal();}}
        enctype="multipart/form-data"
      >
        <label class="d-block mb-4">
          <span class="form-label d-block">Your name</span>
          <input
            required
            name="name"
            type="text"
            class="form-control"
            placeholder=""
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Email address</span>
          <input
            required
            name="email"
            type="email"
            class="form-control"
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Years of experience</span>
          <select required name="experience" class="form-select">
            <option>Less than a year</option>
            <option>1 - 2 years</option>
            <option>2 - 4 years</option>
            <option>4 - 7 years</option>
            <option>7 - 10 years</option>
            <option>10+ years</option>
          </select>
        </label>

       

        <label class="d-block mb-4">
          <span class="form-label d-block">Your CV</span>
          <input required name="cv" type="file" accept=".pdf" class="form-control" />
        </label>

       

        <div class="mb-3">
          <button type="submit" class="btn btn-secondary px-3 rounded-5">
            {submitting?<div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>:
            "Apply"
}
          </button>
        </div>

        
      </form>
    </div>
  </div>
</div>
<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      {/* <img src="..." class="rounded me-2" alt="..."/> */}
      <strong class="me-auto">Bootstrap</strong>
      <small>11 mins ago</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Hello, world! This is a toast message.
    </div>
  </div>
</div>

      </div>
    </div>
  </div>
</div>
            </main>
        </>
    )
}

export default MainPage;