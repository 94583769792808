import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer id="footer" className="footer">

                <div className="container">

                    <div className="row gy-4">

                        <div className="col-lg-5 col-md-12 footer-info">
                            {/* <a href="index.html" className="logo d-flex align-items-center"> */}
                            <div className="d-flex align-content-start ">
                                <img src={require('../../assets/img/logo/logo.png')} className=" img-fluid mt-1 mt-lg-2 mt-xl-0" style={{ height: '3rem', width: '5rem', marginBottom: "30px" }} />

                                <h3 className="pt-lg-1 pt-md-2">IFS India Mercantile Private Limited</h3>
                            </div>
                            {/* </a> */}
                            <p className="text-start">Powering businesses with innovative IT solutions & expert talent.<br />
                                Unleash potential. Partner with IFS for IT & Manpower Consulting.<br />
                                Your trusted partner for success in the digital age.</p>
                            <div className="social-links d-flex mt-4">
                                <a href="https://twitter.com/IfsPvt" className="twitter"><i className="bi bi-twitter"></i></a>
                                <a href="https://www.facebook.com/profile.php?id=100064044726988" className="facebook"><i className="bi bi-facebook"></i></a>
                                {/* <a href="#" className="instagram"><i className="bi bi-instagram"></i></a> */}
                                <a href="https://www.linkedin.com/in/ifs-india/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4 className="text-start">Useful Links</h4>
                            <ul className="fw-medium">
                                <li><Link to="/#home">Home</Link></li>
                                <li><Link to="/#about">About us</Link></li>
                                <li><Link to="#services">Services</Link></li>
                                <li><Link to="/t&c">Terms of service</Link></li>
                                <li><Link to="/privacy-policy">Privacy policy</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4 className="text-start">Our Services</h4>
                            <ul className="fw-medium">
                                <li><a href="#services">Backup & Recovery</a></li>
                                <li><a href="#services">Web Development</a></li>
                                <li><a href="#services">App Development</a></li>
                                <li><a href="#services">Digital Marketing</a></li>
                                <li><a href="#services">Consulting Planning</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-12 footer-contact text-start">
                            <h4 className="text-start">Contact Us</h4>
                            <p className="fw-medium">
                                office No. 30, C Wing <br />
                                KK market Dhankawadi <br />
                                Pune,Maharashtra <br /><br />
                                <strong>Phone:</strong> +91 9383100100<br />
                                <strong>Email:</strong> admin@ifsindia.org<br />
                            </p>

                        </div>

                    </div>
                </div>

                <div className="container mt-4">
                    <div className="copyright">
                        &copy; Copyright <strong><span>IFS INDIA</span></strong>. All Rights Reserved
                    </div>
                    {/* <div className="credits">

                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div> */}
                </div>

            </footer>
            <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

            {/* <div id="preloader"></div> */}
        </>
    )
}

export default Footer;