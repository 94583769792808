import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';



// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const Testimonial = () => {
  return (
    <>
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos="fade-up">

          <div className="section-header">
            <h2>Clients Testimonials</h2>
            <p>We are very proud of our Best performence</p>
          </div>

          <Swiper
            
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={50}
            slidesPerView={3} 
            
            pagination={{ clickable: true }}
            autoplay={true}
            loop={true}

            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 40
              },
              1200: { 
                slidesPerView: 3,
              }
            }}
            
          >
            
            <SwiperSlide><div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img src={require("../../assets/img/testimonials/testimonials-1.jpg")} className="testimonial-img flex-shrink-0" alt="" />
                    <div>
                      <h3>Sachin Diwar</h3>
                      <h4>Sr. IT Director</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    IFS India Mercantile delivered our complex software project on time and within budget. Their team was highly communicative throughout the entire development process, keeping us informed of progress and addressing any concerns promptly. The final product exceeded our expectations and has been instrumental in driving growth for our business.
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div></SwiperSlide>
            <SwiperSlide><div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img src={require("../../assets/img/testimonials/testimonials-4.jpg")} className="testimonial-img flex-shrink-0" alt="" />
                    <div>
                      <h3>Rahul Desai</h3>
                      <h4>Marketing Manager</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    Since partnering with IFS India Mercantile, we've seen a significant improvement in the efficiency of our internal processes. Their custom software development helped us streamline workflows and automate repetitive tasks, freeing up our team to focus on more strategic initiatives. We're incredibly impressed with their expertise.
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div></SwiperSlide>
            <SwiperSlide><div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img src={require("../../assets/img/testimonials/testimonials-2.jpg")} className="testimonial-img flex-shrink-0" alt="" />
                    <div>
                      <h3>Divya Patel</h3>
                      <h4>Founder</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    IFS India Mercantile's manpower consulting services were invaluable in helping us find the perfect talent for a critical software development role. Their deep understanding of the IT landscape and thorough recruitment process ensured we received a pool of highly qualified candidates. We were impressed with their commitment to finding the right fit.
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div></SwiperSlide>
            <SwiperSlide><div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img src={require("../../assets/img/testimonials/testimonials-3.jpg")} className="testimonial-img flex-shrink-0" alt="" />
                    <div>
                      <h3>Sara Wilsson</h3>
                      <h4>Designer</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    IFS India Mercantile has been a reliable partner for all our IT needs. Their team stays current with the latest technologies and trends, offering innovative solutions that keep us ahead of the curve.  We appreciate their proactive approach and ability to anticipate our evolving requirements. We trust them as a long-term partner for our continued success.
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div></SwiperSlide>
            {/* <SwiperSlide><div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img src={require("../../assets/img/testimonials/testimonials-5.jpg")} className="testimonial-img flex-shrink-0" alt="" />
                    <div>
                      <h3>Sanjay Joshi</h3>
                      <h4> IT Manager</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div></SwiperSlide> */}
            {/* <SwiperSlide><div className="swiper-slide">
              <div className="testimonial-wrap">
                <div className="testimonial-item">
                  <div className="d-flex align-items-center">
                    <img src={require("../../assets/img/testimonials/testimonials-2.jpg")} className="testimonial-img flex-shrink-0" alt="" />
                    <div>
                      <h3>Ankit Sharma</h3>
                      <h4>Founder</h4>
                      <div className="stars">
                        <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                      </div>
                    </div>
                  </div>
                  <p>
                    <i className="bi bi-quote quote-icon-left"></i>
                    I've had the pleasure of working with [Company Name] for our IT consulting needs, and they consistently deliver top-notch service. Their team's knowledge and strategic insights have been invaluable in optimizing our IT infrastructure and workflows.
                    <i className="bi bi-quote quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div></SwiperSlide> */}
          </Swiper>

        </div>
      </section>
    </>
  )
}

export default Testimonial;
