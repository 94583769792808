import React from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
const Client = () => {
    return (
        <>
            <section id="clients" className="clients">
                <div className="container" data-aos="zoom-out">
                    


                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={50}
                        slidesPerView={4}
                        pau
                        


                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 40
                            },
                            480: {
                                slidesPerView: 3,
                                spaceBetween: 60
                            },
                            640: {
                                slidesPerView: 4,
                                spaceBetween: 80
                            },
                            992: {
                                slidesPerView: 6,
                                spaceBetween: 120
                            }
                        }}
                        autoplay={false}
                        loop={true}
                        
                        
                    >
                        <SwiperSlide>
                            <div className="swiper-slide"><img src={require('../../assets/img/clients/pngwing.com (1).png')} className="img-fluid" alt="" /></div></SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide"><img src={require('../../assets/img/clients/pngwing.com.png')} className="img-fluid" alt="" /></div></SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide"><img src={require('../../assets/img/clients/pngwing.com (2).png')} className="img-fluid" alt="" /></div></SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide"><img src={require('../../assets/img/clients/pngwing.com (3).png')} className="img-fluid" alt="" /></div></SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide"><img src={require('../../assets/img/clients/sketchapp_logo_icon_168823.png')} className="img-fluid" alt="" /></div></SwiperSlide>
                            
                            <SwiperSlide>
                            <div className="swiper-slide mt-2"><img src={require('../../assets/img/clients/docker.png')} className="img-fluid" alt="" /></div></SwiperSlide>
                            
                        


                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Client;