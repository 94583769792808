import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// import { Link } from "react-router-3dom";

const OurServices = () => {
  const serviceRef = useRef(null);
  const location = useLocation();

  useEffect(()=>{
    if(location.hash==="#services"){
      serviceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  },[location,serviceRef.current])
    return(
        <>
            <section id="services" ref={serviceRef} className="services sections-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Our Services</h2>
          <p>Unlock Your Potential with Our Comprehensive Services: Dive into a World of Innovative Solutions Tailored to Your Business Needs.</p>
        </div>

        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-3 col-md-6">
            <div className="service-item  position-relative">
              <div className="icon">
                <i className="bi bi-database-up"></i>
              </div>
              <h3>Backup & Recovery</h3>
              <p> Our Backup & Recovery services ensure seamless protection of your critical data, employing robust strategies to safeguard against data loss and system failures.</p>
              {/* <Link to="/custom_software_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-laptop"></i>
              </div>
              <h3>Web Development</h3>
              <p> From responsive design to seamless user experience, we specialize in crafting dynamic websites that drive engagement and deliver results.</p>
              {/* <Link to="/web_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-code-square"></i>
              </div>
              <h3>App Development</h3>
              <p>From conceptualization to deployment, we craft tailored solutions to propel your business into the digital forefront.</p>
              {/* <Link to="/app_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-person-square"></i>
              </div>
              <h3>Consulting Planning</h3>
              <p>
              Our seasoned experts provide tailored consulting services, meticulously crafting strategic plans to align with your business objectives</p>
              {/* <Link to="/cloud_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-diagram-3"></i>
              </div>
              <h3>IT Management</h3>
              <p>Efficiently oversee your IT infrastructure with our comprehensive management solutions tailored to your business needs.</p>
              {/* <Link to="/it_consulting" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-shop-window"></i>
              </div>
              <h3>Digital Marketing</h3>
              <p>Unlock the power of online visibility and engagement with our comprehensive digital marketing services.</p>
              {/* <Link to="/digital_marketing" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-shield-check"></i>
              </div>
              <h3>Security</h3>
              <p>Our goal is to propel your to business forward with world-class IT cybersecurity and technology. We provide the expert solutions.</p>
              {/* <Link to="/digital_marketing" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-globe2"></i>
              </div>
              <h3>Internet Thinking</h3>
              <p>Our Internet Thinking service harnesses cutting-edge technologies and innovative strategies to navigate the digital landscape and optimize online presence.</p>
              {/* <Link to="/digital_marketing" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

        </div>

      </div>
    </section>
        </>
    )
}

export default OurServices;