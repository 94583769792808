import React from "react";
import { Link } from "react-router-dom";
const BlogSection = () => {
    return(
        <>
            <section id="blog" className="blog">
      <div className="container" >

        <div className="row gy-4 posts-list">

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src={require("../../assets/img/blog/pexels-lukas-574069.jpg")} alt="" className="img-fluid" />
              </div>

              

              <h2 className="title">
                <Link to="/blog/blog1">Rise of Low-Code Development</Link>
              </h2>

              <p className="post-category">With over a decade experience, we’ve established ourselves…</p>
              <div className="d-flex align-items-center">
                <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author-list text-secondary">Admin</p>
                  
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src={require("../../assets/img/blog/blog-2.jpg")} alt="" className="img-fluid" />
              </div>

              

              <h2 className="title">
              <Link to="/blog/blog2">Embracing Continuous Delivery</Link>
              </h2>

              <p className="post-category">With over a decade experience, we’ve established ourselves…</p>
              <div className="d-flex align-items-center">
                <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author-list text-secondary">Admin</p>
                  
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src={require("../../assets/img/blog/pexels-pixabay-60504.jpg")} alt="" className="img-fluid" />
              </div>

              

              <h2 className="title">
              <Link to="/blog/blog3">The Future of Cybersecurity</Link>
              </h2>

              <p className="post-category">With over a decade experience, we’ve established ourselves…</p>
              <div className="d-flex align-items-center">
                <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author-list text-secondary">Admin</p>
                  
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src={require("../../assets/img/blog/pexels-cottonbro-studio-4065137.jpg")} alt="" className="img-fluid" />
              </div>

              

              <h2 className="title">
              <Link to="/blog/blog4">Remote-First Work Culture</Link>
              </h2>

              <p className="post-category">With over a decade experience, we’ve established ourselves…</p>

              <div className="d-flex align-items-center">
                <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author-list text-secondary">Admin</p>
                  
                </div>
              </div>

            </article>
          </div>

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src={require("../../assets/img/blog/blog-5.jpg")} alt="" className="img-fluid" />
              </div>

              

              <h2 className="title">
              <Link to="/blog/blog5">Importance of Soft Skills</Link>
              </h2>

              <p className="post-category">With over a decade experience, we’ve established ourselves…</p>

              <div className="d-flex align-items-center">
                <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author-list text-secondary">Admin</p>
                  
                </div>
              </div>

            </article>
          </div>

          

          <div className="col-xl-4 col-md-6">
            <article>

              <div className="post-img">
                <img src={require("../../assets/img/blog/blog-6.jpg")} alt="" className="img-fluid" />
              </div>

              

              <h2 className="title">
              <Link to="/blog/blog6">The Ascendancy of Open-Source</Link>
              </h2>

              <p className="post-category">With over a decade experience, we’ve established ourselves…</p>

              <div className="d-flex align-items-center">
                <img src={require("../../assets/img/blog/user.png")} alt="" className="img-fluid post-author-img flex-shrink-0" />
                <div className="post-meta">
                  <p className="post-author-list text-secondary">Admin</p>
                  
                </div>
              </div>

            </article>
          </div>

          

          

          

        </div>




        

      </div>
    </section>
        </>
    )
}


export default BlogSection;