import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";

const Technologies = () => {
  const industriesRef = useRef(null);
  const location = useLocation();

  useEffect(()=>{
    if(location.hash==="#industries"){
      industriesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  },[location,industriesRef.current])
    return(
        <>
            <section id="industries" ref={industriesRef} className="services sections-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Industries</h2>
          <p>Tailored Solutions for Every Industry: Empowering Success Across Diverse Domains.</p>
        </div>

        <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-4 col-md-6">
            <div className="service-item  position-relative">
              <div className="icon">
                <i className="bi bi-heart-pulse"></i>
              </div>
              <h3>Healthcare</h3>
              <p>  Software solutions streamline patient records management and facilitate telemedicine, improving access to healthcare services.</p>
              {/* <Link to="/custom_software_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-bank"></i>
              </div>
              <h3>Finance</h3>
              <p> Advanced software powers secure online banking platforms and financial management systems, ensuring seamless transactions and robust data security.</p>
              {/* <Link to="/web_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-cart"></i>
              </div>
              <h3>E-commerce</h3>
              <p>E-commerce platforms leverage software for online storefronts, inventory management, and customer relationship management, enhancing shopping experiences and driving sales.</p>
              {/* <Link to="/app_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-truck"></i>
              </div>
              <h3>Transportation</h3>
              <p>
              Logistics software coordinates complex supply chains, optimizing route planning, tracking shipments, and improving overall efficiency in transportation operations.</p>
              {/* <Link to="/cloud_development" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-car-front-fill"></i>
              </div>
              <h3>Automotive</h3>
              <p>Automotive software enables vehicle diagnostics, navigation systems, and driver assistance features, enhancing safety, comfort, and efficiency on the road.</p>
              {/* <Link to="/it_consulting" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="bi bi-book"></i>
              </div>
              <h3>Education</h3>
              <p>Educational software enhances learning experiences with interactive tools and virtual classrooms, promoting personalized learning and collaboration.</p>
              {/* <Link to="/digital_marketing" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
            </div>
          </div>

        </div>

      </div>
    </section>
        </>
    )
}

export default Technologies;