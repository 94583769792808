import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const Career = () => {
  const careerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#career") {
      careerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, careerRef.current])
  return (
    <>
      <section id="career" ref={careerRef} className="team">
        <div className="container" data-aos="fade-up">

          <div className="section-header">
            <h2>Careers</h2>
            <p><strong>Join Us:</strong> Grow Your Carrer with Us</p>
          </div>

          <div className="row gy-4">





            <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
              <div className="member">
                <img src={require("../../assets/img/team/pexels-marek-levak-2265488 (1).jpg")} className="img-fluid" alt="" />
                <div>
                  <h4>Jr. JAVA Developer</h4>
                  <span>Skill and Experience 3 Month / Fresher</span>
                  <p>Core java and J2EE Technology Spring and Struts frameworks HTML,CSS,JSP and Servlet.
                    We are seeking a motivated and talented Junior Java Developer to join our dynamic development team. As a Junior Java Developer, you will be responsible for assisting in the design, development, and implementation of Java-based applications.</p>
                  {/* <a className="btn btn-secondary rounded-5" href="mailto:admin@ifsindia.org" >Apply Now</a> */}
                  <button type="button" class="btn btn-secondary rounded-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Apply Now
                  </button>


                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
              <div className="member">
                <img src={require("../../assets/img/team/pexels-djordje-petrovic-2102416 (1).jpg")} className="img-fluid" alt="" />
                <div>
                  <h4>Sr. Java Developer</h4>
                  <span>Skill and Experience 2+ Year Experience</span>
                  <p>Core java and J2EE Technology Spring and Hibernate  frameworks JAX-WS,SOA and Web Service. We are seeking a motivated and talented Junior Java Developer to join our dynamic development team. As a Junior Java Developer, you will be responsible for assisting in the design, development, and implementation of Java-based applications.</p>
                  <button type="button" class="btn btn-secondary rounded-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
              <div className="member">
                <img src={require("../../assets/img/team/pexels-lukas-574071 (1).jpg")} className="img-fluid" alt="" />
                <div>
                  <h4>UI / UX Developer</h4>
                  <span>Skill and Experience 1+ Year Experience</span>
                  <p>HTML5,CSS and JavaScript AJAX,JQUERY,BootStrap,php JAX-WS,SOA and Web Services.We are seeking a talented and innovative UI/UX Developer to join our creative team. As a UI/UX Developer, you will be responsible for creating intuitive and visually appealing user interfaces for our digital products. You will collaborate with cross-functional teams to design and implement user-centric solutions that enhance the overall user experience. </p>
                  <button type="button" class="btn btn-secondary rounded-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
              <div className="member">
                <img src={require("../../assets/img/team/pexels-christina-morillo-1181673 (2).jpg")} className="img-fluid" alt="" />
                <div>
                  <h4>Web Developer</h4>
                  <span>Skill and Experience 3 Month / Fresher</span>
                  <p>HTML, CSS, Javascript jQuery, Angular js/2 Bootstrap,PHP. We are seeking a skilled and passionate Web Developer to join our dynamic team. As a Web Developer, you will be responsible for designing, coding, and modifying websites, from layout to function. You will work closely with our design and content teams to ensure a seamless and visually appealing user experience.</p>
                  <button type="button" class="btn btn-secondary rounded-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Apply Now
                  </button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  )
}

export default Career;