// import logo from './logo.svg';
import React from 'react';
import Header from './Header';
import Hero from './Hero';
import MainPage from './MainPage';
import Footer from './Footer';

function Home() {
  return (
    <>
     <Header />
     <Hero />
     <MainPage />
     <Footer />
    </>
  );
}

export default Home;
