import React, { useEffect, useState } from "react";
import { Link,redirect } from "react-router-dom";

const Header = () => {
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);

    useEffect(() => {
        const headerFixed = () => {
            const selectHeader = document.querySelector('#header');
            if (selectHeader) {
                let headerOffset = selectHeader.offsetTop;
                let nextElement = selectHeader.nextElementSibling;

                if ((headerOffset - window.scrollY) <= 0) {
                    selectHeader.classList.add('sticked');
                    if (nextElement) nextElement.classList.add('sticked-header-offset');
                } else {
                    selectHeader.classList.remove('sticked');
                    if (nextElement) nextElement.classList.remove('sticked-header-offset');
                }
            }
        };

        const navbarlinksActive = () => {
            let navbarlinks = document.querySelectorAll('#navbar a');

            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return;

                let section = document.querySelector(navbarlink.hash);
                if (!section) return;

                let position = window.scrollY + 200;

                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarlink.classList.add('active');
                } else {
                    navbarlink.classList.remove('active');
                }
            });
        };

        const handleScroll = () => {
            headerFixed();
            navbarlinksActive();
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMobileNav = () => {
        setIsMobileNavActive(!isMobileNavActive);
    };

    return (
        <>

            


            <header id="header" className="header align-items-center">

            <div className="d-none d-md-block">
            <section id="topbar" class="topbar d-flex align-items-center">
                <div class="container d-flex justify-content-center justify-content-md-between">
                    <div class="contact-info d-flex align-items-center">
                        <i class="bi bi-envelope d-flex align-items-center"><a href="mailto:admin@ifsindia.org"><strong>admin@ifsindia.org</strong></a></i>
                        <i class="bi bi-geo-alt-fill d-flex align-items-center ms-4"><span><strong>Office No. 30, C Wing, KK Market Dhankawdi, Pune</strong></span></i>
                    </div>
                    <div class="social-links d-none d-md-flex align-items-center">
                        <a href="https://twitter.com/IfsPvt" class="twitter"><i class="bi bi-twitter"></i></a>
                        <a href="https://www.facebook.com/profile.php?id=100064044726988" class="facebook"><i class="bi bi-facebook"></i></a>
                        <a href="https://www.linkedin.com/in/ifs-india/" class="linkedin"><i class="bi bi-linkedin"></i></a>
                </div>
            </div>
        </section >
            </div>

                <header id="header" className={`header d-flex align-items-center ${isMobileNavActive ? 'mobile-nav-active' : ''}`} style={{ width: '100%' }}>
                    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                        <div className="logo d-flex align-items-center">
                            <Link to='/'>
                                <div className="d-flex">
                                    <img src={require('../../assets/img/logo/logo.png')} className='img-fluid' alt="logo" />
                                    {/* <h1>Smartworkideas<span>.</span></h1> */}
                                </div>
                            </Link>
                        </div>


                        <nav id="navbar" className="navbar">

                            <ul>

                                <li>
                                    <i className={`mobile-nav-toggle mobile-nav-hide  bi bi-x ${isMobileNavActive ? '' : 'd-none'}`} onClick={toggleMobileNav} style={{ 'color': 'rgba(255, 255, 255, 0.7)' }}></i>

                                </li>
                                <li><Link to="/#home">Home</Link></li>
                                <li><Link to="/#about">About</Link></li>
                                <li><Link to="/#services">Services</Link></li>
                                <li><Link to="/#industries">Industries</Link></li>
                                <li><Link to="/#career">Careers</Link></li>
                                <li><Link to="/#recent-posts">Blog</Link></li>
                                <li><Link to="/#contact">Contact</Link></li>
                                <li className="me-5"><div className="d-flex px-2 py-1 rounded border-xl">
                                    <div className="d-flex align-items-center fs-4 me-2 d-none d-xl-block text-dark"><i class="bi bi-telephone-inbound"></i></div>
                                    <div className="d-flex align-items-center fs-4 me-2 d-block d-xl-none text-light"><i class="bi bi-telephone-inbound"></i></div>
                                    <div className="d-flex flex-column">
                                        <div className="d-none d-xl-block text-dark">Free Call</div>
                                        <div className="d-block d-xl-none text-light">Free Call</div>
                                        <div><a href="tel:+919383100100">+91 9383100100</a> </div>
                                    </div>

                                </div></li>
                            </ul>
                        </nav>


                        <i className={`mobile-nav-toggle mobile-nav-show bi bi-list ${isMobileNavActive ? 'd-none' : ''}`} onClick={toggleMobileNav} style={{ 'color': '#272727' }}></i>
                    </div>
                </header>



            </header>


        </>
    )
}

export default Header;