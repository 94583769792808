import React from "react";
import Header from "../Home/Header";
import BlogMain from "./BlogMain";
import Footer from "../Home/Footer";


const BlogApp = () => {
    return (
        <>
            <Header />
            <BlogMain />
            <Footer />
        </>
    )
}

export default BlogApp;