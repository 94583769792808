
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const AboutUs = () => {
    const aboutRef = useRef(null);
    const location= useLocation();

    useEffect(()=>{
        if(location.hash==="#about"){
            aboutRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[location,aboutRef.current])
    return (
        <>
            <section id="about" ref ={aboutRef} className="about">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>About Us</h2>
                        <p>Empowering Your Digital Success: IFS INDIA - Your Premier Destination for Innovative IT Solutions</p>
                    </div>

                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <h3>We Are Innovative IT Solutions & Services Company</h3>
                            <img src={require('../../assets/img/about.jpg')} className="img-fluid rounded-4 mb-4" alt="" />
                            <p>IFS India Mercantile Pvt Ltd is an experienced IT company specializing in software development, network and infrastructure, cybersecurity, and cloud computing. We offer a wide range of services to meet the diverse needs of our clients, from startups to large enterprises. Our team of experts is dedicated to providing our clients with the best possible service and support. We are committed to helping our clients achieve their business goals through innovative and cost-effective IT solutions.</p>

                        </div>

                        <div className="col-lg-6">
                            <div className="content ps-0 ps-lg-5">
                                <p className="fst-italic">
                                    Our Commitment to Excellence
                                </p>
                                <ul>
                                    <li className="text-start"><i className="bi bi-check-circle-fill"></i> Tailored Solutions: Crafting bespoke software services to meet your unique business requirements.</li>
                                    <li className="text-start"><i className="bi bi-check-circle-fill"></i> Expertise and Innovation: Harnessing cutting-edge technologies to drive digital transformation and innovation.</li>
                                    <li className="text-start"><i className="bi bi-check-circle-fill"></i> Client-Centric Approach: Dedicated to delivering exceptional results and exceeding client expectations through personalized attention and collaborative partnerships.</li>
                                </ul>
                                <p>

                                    At IFS INDIA, we're all about you. Our collaborative approach ensures solutions that surpass expectations, driving lasting success. Unlock your business's full potential with us today.
                                </p>

                                <div className="position-relative mt-4">
                                    <img src={require('../../assets/img/about-2.jpg')} className="img-fluid rounded-4" alt="" />
                                    <a href="https://youtu.be/XYqvFF0EZdE" className="glightbox play-btn"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;