import logo from './logo.svg';
import "bootstrap-icons/font/bootstrap-icons.css";
import './App.css';
import './main.css';

import 'swiper/css/bundle';


import Home from './components/Home/Home.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BlogApp from './components/DetailBlog/BlogApp.js';
import DetailBlog from './components/DetailBlog/DetailBlog.js';
import Terms from './components/Home/Terms.js';
import PrivacyPolicy from './components/Home/PrivacyPolicy.js';
import PageNotFound from './components/Home/PageNotFound.js';

// import BlogDetail from './components/DetailBlog/BlogDetail.js';

function App() {
  return (
    <div className="App">
     <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/all-blog' element={<BlogApp />} />
        <Route path='/blog/:blogname' element={<DetailBlog />} />
        <Route path='/t&C' element={<Terms />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/*' element={<PageNotFound />} />

        {/* <Route path='/blog_detail' element={<BlogDetail />} /> */}
      </Routes>

     </BrowserRouter>
    </div>
  );
}

export default App;
