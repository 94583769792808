import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const PageNotFound = ()=>{
    return(
        <>
        <Header />
            <div className="container d-flex flex-column justify-content-center align-items-center" style={{height:'44vh'}}>
                <h1>404</h1>
                <h1>Page Not Found</h1>
                <p>Sorry, the page you are looking for could not be found</p>
                <Link to="/" className="btn btn-secondary rounded-5">Go Back</Link>
            </div>
            <Footer />
        </>
    )
}

export default PageNotFound;