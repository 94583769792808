import React from "react";

const Faq = () => {
  return (
    <>
      <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">

          <div className="row gy-4">

            <div className="col-lg-4">
              <div className="content px-xl-5">
                <h3>Frequently Asked <strong>Questions</strong></h3>
                <p>
                Get quick answers to common questions about our products, services, and policies in our FAQ section. Simplify your search and find what you need fast.
                </p>
              </div>
            </div>

            <div className="col-lg-8">

              <div className="accordion accordion-flush" id="faqlist" data-aos="fade-up" data-aos-delay="100">

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                      <span className="num">1.</span>
                      What services does your IT software company offer?
                    </button>
                  </h3>
                  <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                    Our IT software company offers a wide range of services, including custom software development, web development, mobile app development, cloud computing solutions, IT consulting services, and cybersecurity solutions.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                      <span className="num">2.</span>
                      How can I request a quote for a custom software development project?
                    </button>
                  </h3>
                  <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                    You can request a quote for your custom software development project by contacting us through our website or by email. Our team will review your requirements and provide you with a detailed quote tailored to your specific needs.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                      <span className="num">3.</span>
                      What platforms do you develop mobile applications for?
                    </button>
                  </h3>
                  <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                    We develop mobile applications for both iOS and Android platforms, ensuring compatibility across a wide range of devices.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                      <span className="num">4.</span>
                      How long does it typically take to develop a custom software solution?
                    </button>
                  </h3>
                  <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                    The timeline for developing a custom software solution can vary depending on the complexity of the project and your specific requirements. We'll work closely with you to establish a timeline that meets your needs while ensuring quality and efficiency.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h3 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                      <span className="num">5.</span>
                      What security measures do you have in place to protect client data?
                    </button>
                  </h3>
                  <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                    We take data security very seriously and implement industry-standard security measures to protect client data. This includes encryption, access controls, regular security audits, and compliance with relevant data protection regulations.
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Faq;